import React from "react";
import addDeptGrpImg from "../../assets/images/background/add-dept-grp-img.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useFormik } from "formik";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import AddDeptShareDeptLink from "./AddDeptShareDeptLink";
import PaymentSuccessfulModal from "../comman/PaymentSuccessfulModal";
import CouponSuccessfulModal from "../comman/CouponSuccessfulModal";
import { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

const AddDeptPaymentModal = (props) => {
   const [selectedPlan, setSelectedPlan] = useState();
   const [amount, setAmount] = useState();
   const [isHoveredQuarterly, setIsHoveredQuarterly] = useState(false);
   const [isHoveredTopup, setIsHoveredTopup] = useState(false);
   const [openShareLinkModal, setOpenShareLinkModal] = useState(false);
   const [paymentModalShow, setPaymentModalShow] = useState(false);
   const [couponModalShow, setCouponModalShow] = useState(false);
   // const subscriptionData = useSelector((state) => state.payment.paymentData);
   const [subscriptionData, setSubscriptionData] = useState(null);
   const [calculationData, setCalculationData] = useState(null);
   const [remainingDaysCost, setRemainingDaysCost] = useState(null);

   const navigate = useNavigate();
   console.log("aaaa", localStorage.getItem("persist:root"));

   // const selectPlanButtonStyleQuarterly = {
   //   backgroundColor:
   //     isHoveredQuarterly || selectedPlan === "quaterly" ? "#6993ff" : "",
   //   color:
   //     isHoveredQuarterly || selectedPlan === "quaterly" ? "#fff" : "#6993ff",
   //   transition: "color 0.2s ease",
   // };
   const selectPlanButtonStyleTopup = {
      backgroundColor: isHoveredTopup ? "#6993ff" : "",
      color: isHoveredTopup ? "#fff" : "#6993ff",
      transition: "color 0.2s ease",
   };

   const departmentListCount = props?.newDeptList?.length;
   const costPerDepartment = 100;
   const discountPercentage = 5;
   const quarterlyCost = departmentListCount * costPerDepartment * 3;
   // const id = props.organizationId;

   console.log("Organization id is here in payment *****", props.organizationId);

   const getSubscriptionDataPayment = () => {
      if (props.organizationId) {
         API.Auth.getSubscriptionData(props.organizationId)
            .then((response) => {
               if (response.data.status === 200) {
                  console.log("hhhhhh#####", response.data);
                  setSubscriptionData(response.data.data[0]);
               }
            })
            .catch((err) => {
               if (err.response.status === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      }
   };

   const couponForm = useFormik({
      initialValues: {
         coupon: "",
      },
      onSubmit: async (values, { setSubmitting, setErrors }) => {
         const { coupon } = values;
         setSubmitting(true);
         try {
            const res = await API.Auth.ValidateCoupon({
               data: {
                  coupon,
                  departmentCount: props?.newDeptList.length ?? 1,
                  department_array: props?.newDeptList?.map((dept) => {
                     const { dept_email, ...rest } = dept;
                     return { ...rest, email: dept_email };
                  }),
               }
            });
            if (res.data.statusCode === 200) {
               toast.success("Payment Successful");
               const closeButton = document.getElementById("closeUpdateButtonAddDept");
               closeButton?.click();
               API.Auth.ShareInviteAPI({
                  data: {
                     flag: 5,
                  },
               }).catch((err) => {
                  console.log("🐱‍💻🐱‍💻", err);
               });
               setCouponModalShow(true);
               setIsHoveredTopup(false);
               setTimeout(() => {
                  window.location.reload();
               }, 3000)
            }
            else {
               toast.error("Invalid Coupon");
            }
         } catch (err) {
            toast.error("Invalid Coupon");
            console.error(err);
         } finally {
            setSubmitting(false);
         }
      },
      validate: (values) => {
         const errors = {};
         if (!values.coupon) {
            errors.coupon = "Please enter coupon";
         }
         return errors;
      },
   });

   const get_Calculations_Data = () => {
      console.log("departmentListCount", departmentListCount);
      if (departmentListCount) {
         API.Auth.getCalculationsData(departmentListCount)
            .then((response) => {
               if (response.data.statusCode === 200) {
                  console.log("calculationData", response.data.data);
                  setCalculationData(response.data.data);
               }
            })
            .catch((err) => {
               if (err.response.statusCode === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      }
   };

   useEffect(() => {
      get_Calculations_Data();
   }, [departmentListCount]);

   useEffect(() => {
      getSubscriptionDataPayment();
   }, [props.organizationId]);

   // const remainingDays = () => {
   //   const startDate = new Date(`${subscriptionData?.start_date}`);
   //   const endDate = new Date(`${subscriptionData?.end_date}`);
   //   const today = new Date();

   //   const durationMs = endDate.getTime() - startDate.getTime();

   //   const daysInPlan = Math.ceil(durationMs / (1000 * 60 * 60 * 24));

   //   const remainingMs = endDate.getTime() - today.getTime();
   //   const daysRemaining = Math.ceil(remainingMs / (1000 * 60 * 60 * 24));

   //   return daysRemaining;
   // };

   // const totalCostOneYear = 1200;
   // const startDate = new Date(`${subscriptionData?.start_date}`);
   // const endDate = new Date(subscriptionData?.end_date);
   // const today = new Date();

   // const costPerDay = (departmentListCount * costPerDepartment * 12) / 365;

   // const daysPassed = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));

   // const costForDaysPassed = costPerDay * daysPassed;

   // const remainingAmount = totalCostOneYear - costForDaysPassed;
   // const topupAmount = remainingDays() * costPerDay * departmentListCount;
   // const totalTopupAmount = remainingAmount + topupAmount;

   // const oldDeptQuarterlyCost =
   //   subscriptionData?.department_count * costPerDepartment * 3;
   // const totalQuarterlyCost = quarterlyCost + oldDeptQuarterlyCost;

   function addMonthsToDate(dateStr, monthsToAdd) {
      // Parse the date string and create a Date object
      const date = new Date(dateStr);

      // Add the specified number of months
      date.setMonth(date.getMonth() + monthsToAdd);

      // Format the date back to the YYYY/MM/DD format
      const year = date.getFullYear();
      // getMonth() returns 0-11, adding 1 to normalize it to the human-readable format
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);

      return `${year}/${month}/${day}`;
   }

   const showRazorpay = async () => {
      try {
         const orderResponse = await API.Auth.createPaymentOrder({
            data: {
               amount: Number(amount),
            },
         });

         if (orderResponse.data.statusCode === 200) {
            const orderData = orderResponse.data.data;

            const options = {
               key: "rzp_test_BF1ASnSComgPmw",
               amount: orderData?.amount?.toString(),
               currency: "INR",
               order_id: orderData?.razorpay_order_id,
               name: "EasyOKR",
               description: "Thank you for the payment.",
               image: "https://serverqodequay.s3.amazonaws.com/tajurba/profile/1705904286856_img.jpeg",
               handler: function (response) {
                  console.log("Transaction successful:", response);
                  // setTrasactionResponse(response);
                  // You can handle the successful transaction here
                  // Example: Send payment details to your backend
                  API.Auth.createMultipleDepartment({
                     data: {
                        department_array: props?.newDeptList?.map((dept) => {
                           const { dept_email, ...rest } = dept;
                           return { ...rest, email: dept_email };
                        }),
                        // flag: 4,
                     },
                  })
                     .then(async (createDepartmentResponse) => {
                        if (createDepartmentResponse.data.statusCode === 200) {
                           await API.Auth.createSubscription({
                              data: {
                                 amount: Number(amount),
                                 razorpay_payment_id: response?.razorpay_payment_id,
                                 razorpay_order_id: response?.razorpay_order_id,
                                 expiry_date:
                                    selectedPlan === "quaterly"
                                       ? addMonthsToDate(subscriptionData?.end_date, 3)
                                       : subscriptionData?.end_date,
                                 department_array: createDepartmentResponse?.data?.data?.map((item) => item?._id),
                                 organization_id: createDepartmentResponse?.data?.data[0]?.organization_id,
                                 subscription_data: {
                                    plan_type: selectedPlan,
                                    organization_id: createDepartmentResponse?.data?.data[0]?.organization_id,
                                    payment_detail_id: "",
                                    department_array: [],
                                    department_count: createDepartmentResponse?.data?.data?.length,
                                    payment_status: "success",
                                    subscription_status: "active",
                                    start_date: subscriptionData?.start_date,
                                    end_date:
                                       selectedPlan === "quaterly"
                                          ? addMonthsToDate(subscriptionData?.end_date, 3)
                                          : subscriptionData?.end_date,
                                    discount_amount: Number(amount),
                                    discount_percentage: discountPercentage,
                                 },
                              },
                           })
                              .then((createSubscriptionResponse) => {
                                 console.log("createSubscriptionResponse?.data", createSubscriptionResponse?.data);
                                 if (createSubscriptionResponse?.data?.statusCode === 200) {
                                    toast.success("Payment Successful");
                                    const closeButton = document.getElementById("closeUpdateButtonAddDept");
                                    closeButton?.click();
                                    setPaymentModalShow(true);
                                    setOpenShareLinkModal(true);
                                    setIsHoveredTopup(false);

                                    localStorage.setItem(
                                       "PaymentDetailsForInvoice",
                                       JSON.stringify(createSubscriptionResponse?.data)
                                    );

                                    API.Department.GetAllDepartmentAPI()
                                       .then((response) => {
                                          if (response.data.statusCode === 200) {
                                             console.log(response.data.data);
                                             props?.setDepartmentList(response.data.data);
                                          }
                                       })
                                       .catch((err) => {
                                          if (err.response.status === 403) {
                                             //   navigate("/");
                                             console.log(err);
                                          }
                                          console.log(err);
                                       });

                                    // setPaymentSuccessModal(true);
                                    // setAfterPayment(true)
                                    // setPaymentSuccessfulRes(createSubscriptionResponse?.data)
                                    // dispatch(paymentData(createSubscriptionResponse?.data));
                                 }
                              })
                              .catch((err) => {
                                 // toast.error("Subscription Failed")
                                 console.error(err);
                              });
                        }
                     })
                     .catch((err) => {
                        console.error(err);
                     });
               },
               prefill: {
                  name: "Rajat",
                  email: "rajat@rajat.com",
                  phone: "9899999999",
               },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
         }
      } catch (error) {
         console.error("Error:", error);
         alert(error.message);
      }
   };

   const newArrayv = props?.newDeptList?.map((dept) => {
      const { dept_email, ...rest } = dept;
      return { ...rest, email: dept_email };
   });

   useEffect(() => {
      setTimeout(() => {
         setPaymentModalShow(false);
      }, 3000);
   }, [paymentModalShow]);

   useEffect(() => {
      setTimeout(() => {
         setCouponModalShow(false);
      }, 3000);
   }, [couponModalShow]);

   console.log("props are here >>>>>>>>>>", props);
   console.log("calculations plan type are here >>>>>>>>>>>", String(calculationData?.planType));
   console.log("razor pay amount is  >>>>>>>>>>>", amount);
   console.log("calculations amount are here >>>>>>>>>>>", calculationData?.remainingDaysCost);
   console.log("hovered popup >>>>>>>>>>>", isHoveredTopup);
   return (
      <>
         {console.log("@$$$$$$$$$$$$$$$$$$$$$$$$$$$$ hello", subscriptionData)}
         <div
            className="modal fade"
            id="addDeptPaymentModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ zIndex: "9999" }}
         >
            <div className="modal-dialog shadow-none modal-dialog-centered modal-dialog-scrollable bg-transparent">
               <div className="modal-content position-relative radius-20">
                  <div className="modal-body p-4">
                     <button
                        type="button"
                        id="closeUpdateButtonAddDept"
                        className="btn-close position-absolute custom-close-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                           props.clearNewDeptList();
                           setIsHoveredTopup(false);
                        }}
                     // onClick={() => {
                     //   formik.resetForm()
                     // }}
                     />
                     <div className="row align-items-center">
                        <div className="col-md-6 d-flex flex-column align-items-center">
                           <p className="textGrey fw-regular mb-2">Total Department Added</p>
                           <p
                              style={{
                                 fontSize: "30px",
                                 fontWeight: "600",
                                 color: "#6993FF",
                              }}
                           >
                              {calculationData?.totalDepartmentCount ? calculationData?.totalDepartmentCount : 0}
                           </p>
                           <img src={addDeptGrpImg} width="60%" height="60%" alt="Group" />
                        </div>
                        <div className="col-md-6">
                           <p className="textGrey fw-regular mb-2">Choose a plan</p>
                           <p className="textGrey fw-regular mb-0">Total Amount Payable</p>
                           <small class="lightGrey my-2 d-block">
                              Choose your preferred package and proceed with the payment.
                           </small>
                           <div className="d-flex align-items-center gap-3">
                              <div className="flex-grow-1">
                                 <input
                                    className="form-control"
                                    style={{ height: "2.5rem" }}
                                    type="text"
                                    placeholder="Enter Coupon Code"
                                    name="coupon"
                                    value={couponForm.values.coupon}
                                    onChange={couponForm.handleChange}
                                 />
                                 {couponForm.errors.coupon && couponForm.touched.coupon ? (
                                    <div className="text-danger mt-1">{couponForm.errors.coupon}</div>
                                 ) : null}
                              </div>
                              <button
                                 className="btn btn-primary"
                                 style={{ height: "2.5rem" }}
                                 onClick={couponForm.handleSubmit}
                              >
                                 Validate Coupon
                              </button>
                           </div>
                           <b className="textGrey fw-regular mb-0">Inclusive GST (18%)</b>
                           {/* <div className={`list-group`} id="list-tab" role="tablist"> */}
                           {/* <div
                      class={`price-tabs mb-3 textBlueLight1`}
                      onClick={() => {
                        setSelectedPlan("quaterly");
                        setAmount(totalQuarterlyCost?.toFixed(0));
                      }}
                      style={selectPlanButtonStyleQuarterly}
                      onMouseEnter={() => setIsHoveredQuarterly(true)}
                      onMouseLeave={() => setIsHoveredQuarterly(false)}
                    >
                      <div className="row m-0">
                        <div className="col-md-9 p-0">
                          <h4 className="h5 fw-normal">
                            {" "}
                            Quarterly Plan{" "}
                            <small className="small p fs-6">(90 days)</small>
                          </h4>
                          <small className="mb-0">
                            The cost for an individual department is{" "}
                            {costPerDepartment ? costPerDepartment : 100} per
                            month.
                          </small>
                        </div>
                        <div className="col-md-3 pe-0">
                          <h3 className="h4 text-right">
                            ₹{totalQuarterlyCost ? totalQuarterlyCost : 0}
                          </h3>
                        </div>
                      </div>
                    </div> */}
                           {/* </div> */}

                           <div className={`list-group mt-1`} id="list-tab" role="tablist">
                              <div
                                 class={`price-tabs1 mb-3 textBlueLight1`}
                                 onClick={() => {
                                    setSelectedPlan(calculationData?.planType);
                                    setAmount(
                                       // calculationData?.remainingDaysCost.toFixed(0)
                                       calculationData?.remainingDaysCostWithGst.toFixed(0)
                                    );
                                    setRemainingDaysCost(calculationData?.remainingDaysCost.toFixed(0));
                                    setIsHoveredTopup(true);
                                 }}
                                 style={selectPlanButtonStyleTopup}
                              // onMouseEnter={() => setIsHoveredTopup(true)}
                              // onMouseLeave={() => setIsHoveredTopup(false)}
                              >
                                 <div className="row m-0">
                                    <div className="col-md-9 p-0">
                                       <h4 className="h5 fw-normal">
                                          {" "}
                                          Top up Plan{" "}
                                          <small className="small p fs-6">(Remaining {calculationData?.remainingDays})</small>
                                       </h4>
                                       <small className="mb-0">Combined with your existing plan.</small>
                                    </div>
                                    <div className="col-md-3 pe-0">
                                       <h3 className="h4 text-right">
                                          ₹
                                          {calculationData?.remainingDaysCost
                                             ? calculationData?.remainingDaysCost?.toFixed(0)
                                             : 0}
                                       </h3>
                                       <h4 className="h6 text-center"></h4>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="d-flex justify-content-center">
                              <button
                                 // data-bs-target="#shareDeptLinkModal"
                                 // data-bs-toggle="modal"
                                 type="button"
                                 className="btn sign-up-button-color btn-primary text-white px-5 mt-1 w-100 max-width-277"
                                 style={{
                                    backgroundColor: amount ? "#6993ff" : "#acacac",
                                 }}
                                 disabled={!isHoveredTopup}
                                 onClick={showRazorpay}
                              //   onClick={() => {
                              //     setPaymentModalShow(true);
                              //   }}
                              >
                                 Pay Now
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {openShareLinkModal && (
            <AddDeptShareDeptLink
               amount={amount}
               departmentListCount={departmentListCount}
               totalDepartmentCount={calculationData?.totalDepartmentCount}
               newDeptList={props?.newDeptList}
               setOpenShareLinkModal={setOpenShareLinkModal}
               newDeptList1={props.newDeptList1}
               remainingDaysCost={remainingDaysCost}
            // dataForPDF={calculationData}
            />
         )}
         {paymentModalShow && <PaymentSuccessfulModal setShow={setPaymentModalShow} show={paymentModalShow} />}
         {couponModalShow && <CouponSuccessfulModal setShow={setCouponModalShow} show={couponModalShow} />}
      </>
   );
};

export default AddDeptPaymentModal;
