import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import otpSentIcon from "../../assets/images/Icons/Pics/Icon awesome-check-circle.png";
// import setObjective from "../../assets/images/Icons/Pics/Set Objective.png";
// import improveProductivity from "../../assets/images/Icons/SVG/Selected Org.svg";
// import editIcon from "../../assets/images/Icons/SVG/Edit Icon.svg";
// import deleteIcon from "../../assets/images/Icons/SVG/Delete Icon.svg";
// import departmentIcon from "../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
// import API from "../../Api/Api";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CompanyDetail from "./Registration/CompanyDetail";
import EmailVerification from "./Registration/EmailVerification";
import SetObjective from "./Registration/SetObjective";
import DefineDepartment from "./Registration/DefineDepartment";
import ShareAccess from "./Registration/ShareAccess";
import RouteName from "../../Routes/Routename";

const steps = [
  "Company Details",
  "Email Verification",
  "Set Objective",
  "Define Department",
  "Share access",
];

const Registration = () => {
  const [skipped, setSkipped] = React.useState(new Set());
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const check = queryParams.get("id");
  const email = queryParams.get("email");
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(parseInt(check));
  const [departmentListLocallyPublish, setDepartmentListLocallyPublish] =
    useState(null);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    const nextStep = activeStep + 1;
    console.log("Next Step:", nextStep); // Check the value of nextStep

    setActiveStep(nextStep);
    setSkipped(newSkipped);

    navigate(`../${RouteName.Auth.Registration}?id=${nextStep}`, {
      replace: true,
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <div className="login-page">
        <div className="container-fluid main-container vh-100 p-0">
          <div className="row h-100">
            <div className="col-12 vh-100 p-5">
              <h1 className="bannerHeading fs-3 mb-5">
                EASY<span className=" fs-3">OKR</span>
              </h1>
              <div className="row h-100 d-flex justify-content-center">
                <div className="col-xl-10 col-12 vh-100">
                  <div className="row justify-content-center registration-form-container w-100 px-3">
                    <div className="col-xl-11 col-12 h-100">
                      <div className="d-flex justify-content-between">
                        <h2 className="mb-0 textDark">New Registration</h2>
                        <a href="/" className="textDark">
                          <i class="fa-solid fa-xmark"></i>
                        </a>
                      </div>

                      <div className="row mt-4">
                        <div className="col-12">
                          <Box sx={{ width: "100%" }}>
                            <Stepper activeStep={activeStep}>
                              {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                  labelProps.optional = (
                                    <Typography variant="caption"></Typography>
                                  );
                                }
                                if (isStepSkipped(index)) {
                                  stepProps.completed = false;
                                }
                                return (
                                  <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>
                                      {label}
                                    </StepLabel>
                                  </Step>
                                );
                              })}
                            </Stepper>
                            {activeStep === steps.length ? (
                              <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                  All steps completed - you&apos;re finished
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                  }}
                                >
                                  <Box sx={{ flex: "1 1 auto" }} />
                                  <Button onClick={handleReset}>Reset</Button>
                                </Box>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {(parseInt(activeStep) === 0 ||
                                  activeStep === null) && (
                                  <div>
                                    <CompanyDetail handleNext={handleNext} />
                                  </div>
                                )}
                                {(parseInt(activeStep) === 1 ||
                                  activeStep === null) && (
                                  <div>
                                    <EmailVerification
                                      handleNext={handleNext}
                                    />
                                  </div>
                                )}
                                {parseInt(activeStep) === 2 && (
                                  <div>
                                    <SetObjective handleNext={handleNext} />
                                  </div>
                                )}
                                {parseInt(activeStep) === 3 && (
                                  <div>
                                    <DefineDepartment
                                      handleNext={handleNext}
                                      setDepartmentListLocallyPublish={
                                        setDepartmentListLocallyPublish
                                      }
                                      departmentListLocallyPublish={
                                        departmentListLocallyPublish
                                      }
                                    />
                                  </div>
                                )}
                                {parseInt(activeStep) === 4 && (
                                  <div>
                                    <ShareAccess
                                      handleNext={handleNext}
                                      handleBack={handleBack}
                                      departmentListLocallyPublish={
                                        departmentListLocallyPublish
                                      }
                                      setDepartmentListLocallyPublish={
                                        setDepartmentListLocallyPublish
                                      }
                                    />
                                  </div>
                                )}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                  }}
                                >
                                  <Box sx={{ flex: "1 1 auto" }} />
                                </Box>
                              </React.Fragment>
                            )}
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
