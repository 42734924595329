import React from "react";
import { Modal, Button } from "react-bootstrap";
import successIcon from "../../assets/images/Icons/SVG/success.svg";

const CouponSuccessfulModal = (props) => {
  const overlayClass = props?.show
    ? "modal-overlay-payment active"
    : "modal-overlay-payment";
  const modalClass = props?.show ? "modal-payment active" : "modal-payment";

  return (
    <div className={overlayClass} style={{ zIndex: "9999" }}>
      <div className={modalClass}>
        <div className="modal-header-payment">
          <button
            type="button"
            id="closeUpdateButtonAddDept"
            className="btn-close position-absolute custom-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              props?.setShow(false);
            }}
          />
        </div>
        <div className="text-center pt-0 modal-body-payment">
          <img
            src={successIcon}
            alt="sucess"
            width="40"
            height="40"
            className="mx-auto"
          />
          <p className="textDarkBlack mt-3 poppinsSemibold">
            Coupon Payment Successful
          </p>
          <p className="textDarkBlack">
            Your coupon has been successfully processed.
          </p>
          <Button
            type="button"
            className="btn btn sign-up-button-color text-white px-5 mt-1 w-100 max-width-277"
            onClick={() => {
              props?.setShow(false);
            }}
          >
            Okay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CouponSuccessfulModal;
