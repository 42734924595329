import React, { useEffect, useMemo, useState } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import departmentIcon from "../../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import { useFormik } from "formik";
import API from "../../../Api/Api";
import RouteName from "../../../Routes/Routename";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const DefineDepartment = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("editId");

  // const [departmentList, setDepartmentList] = useState(null);
  // const [departmentData, setDepartmentDataByID] = useState(null);
  const [departmentListLocally, setDepartmentListLocally] = useState(null);
  const [isEditing, setEditing] = useState(false); // New state for edit mode
  const [editingId, setEditingId] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const navigate = useNavigate();
  //   const validate = (values) => {
  //     const errors = {};
  //     const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  //     const spaceReg = /^\S.*\S$/;
  //     if (!values.dept_name) {
  //       errors.dept_name = "Please enter department name";
  //     } else if (!spaceReg.test(values.dept_name)) {
  //       errors.dept_name = "Cannot start & end with a space";
  //     } else {
  //       const selectedDeptName = departmentListLocally?.find(
  //         (item) =>
  //           item?.dept_name === values.dept_name && item?.dept_name !== editingId
  //       );
  //       if (selectedDeptName) {
  //         errors.dept_name = `Entered department name already exists`;
  //       }
  //     }
  //     if (!values.email) {
  //       errors.email = "Please enter email";
  //     } else if (!regex.test(values.email)) {
  //       errors.email = "This is not a valid email format";
  //     } else {
  //       const selectedEmailId = departmentListLocally?.find(
  //         (item) => item?.email === values.email && item?.dept_name !== editingId
  //       );
  //       if (selectedEmailId) {
  //         errors.email = `Entered department email already exists`;
  //       }
  //     }

  //     return errors;
  //   };

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    const spaceReg = /^\S.*\S$/;

    if (!values.dept_name) {
      errors.dept_name = "Please enter department name";
    } else if (!spaceReg.test(values.dept_name)) {
      errors.dept_name = "Cannot start & end with a space";
    } else {
      const selectedDeptName = departmentListLocally?.find(
        (item) =>
          item?.dept_name === values.dept_name && item?.dept_name !== editingId
      );
      if (selectedDeptName) {
        errors.dept_name = `Entered department name already exists`;
      }
    }

    if (!values.email) {
      errors.email = "Please enter email";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
    } else {
      const selectedEmailId = departmentListLocally?.find(
        (item) => item?.email === values.email && item?.dept_name !== editingId
      );
      if (selectedEmailId) {
        errors.email = `Entered department email already exists`;
      }
    }

    // Check for multiple email addresses without proper separation
    if (regex.test(values.email)) {
      const emailParts = values.email.split("@");
      if (
        emailParts.length > 2 ||
        (emailParts.length === 2 && emailParts[1].includes("@"))
      ) {
        errors.email = "Please enter only one email address";
      }
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      dept_name: "",
      email: "",
    },
    onSubmit: () => {
      // handleSave();
      if (editingId) {
        updateOrganization();
      } else {
        createDepartment();
      }
    },
    validate,
  });

  // useEffect(() => {
  //   if (editId) {
  //     setDepartmentListLocally(props?.departmentListLocallyPublish);
  //     const objectiveToEdit = props?.departmentListLocallyPublish?.find(
  //       (item) => item?.dept_name === editId
  //     );
  //     setEditing(true);
  //     setEditingId(editId);
  //     console.log("🐱‍🐉🐱‍🐉", objectiveToEdit);
  //     if (objectiveToEdit) {
  //       formik.setValues({
  //         dept_name: objectiveToEdit?.dept_name,
  //         email: objectiveToEdit?.email,
  //       });
  //     }
  //   }
  // }, [editId]);

  useEffect(() => {
    const storedDepartments =
      JSON.parse(localStorage.getItem("departmentListLocally")) || [];
    setDepartmentListLocally(storedDepartments);

    if (editId) {
      const objectiveToEdit = storedDepartments.find(
        (item) => item?.dept_name === editId
      );
      setEditing(true);
      setEditingId(editId);
      if (objectiveToEdit) {
        formik.setValues({
          dept_name: objectiveToEdit?.dept_name,
          email: objectiveToEdit?.email,
        });
      }
    }
  }, [editId]);

  const createDepartment = () => {
    // API.Auth.CreateDepartment({
    //   data: {
    //     dept_name: formik.values.dept_name,
    //     email: formik.values.email,
    //     flag_dept: 4,
    //   },
    // })
    //   .then((response) => {
    //     if (response?.data?.statusCode === 200) {
    //       console.log(response, "response");
    //       getAlldepartments();
    //       formik.resetForm();
    //     } else {
    //       // Handle error or provide feedback to the user
    //       toast.error(response?.data?.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err, "err");
    //   });
    const newObjective = {
      dept_name: formik.values.dept_name,
      email: formik.values.email,
    };
    const newList = departmentListLocally
      ? [...departmentListLocally, newObjective]
      : [newObjective];
    setDepartmentListLocally(newList);
    localStorage.setItem("departmentListLocally", JSON.stringify(newList));
    formik.resetForm();
  };

  // const getAlldepartments = async () => {
  //   await API.Auth.GetAllOrgDepartmentsAPI()
  //     .then((response) => {
  //       setDepartmentList(response.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const getSingledepartmentById = () => {
  //   if (editId) {
  //     API.Auth.GetSingleDeptInfo({ editId })
  //       .then((response) => {
  //         setDepartmentDataByID(response.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };
  // useEffect(() => {
  //   getSingledepartmentById();
  // }, [editId]);

  const handleDelete = (deptName) => {
    setDeleteId(deptName);
  };

  const deleteDepartment = () => {
    // API.Auth.DeactivateDepartment({
    //   data: {
    //     _id: deleteId,
    //   },
    // })
    //   .then((response) => {
    //     getAlldepartments();
    //     document.querySelector("#DeleteDepartemnt").click();
    //     formik.resetForm();
    //     setEditing(false);
    //     setEditingId("");
    //   })
    //   .catch((err) => console.log(err));

    const filteredList = departmentListLocally?.filter(
      (item) => item?.dept_name !== deleteId
    );

    setDepartmentListLocally(filteredList);
    localStorage.setItem("departmentListLocally", JSON.stringify(filteredList));
    document.querySelector("#DeleteDepartemnt").click();
    formik.resetForm();
    setEditing(false);
    setEditingId(null);
  };

  // const handleEdit = (deptName) => {
  //   const objectiveToEdit = departmentListLocally?.find((item) => item?.dept_name === deptName);
  //   if (objectiveToEdit) {
  //     formik.setValues({
  //       dept_name: objectiveToEdit.dept_name,
  //       email: objectiveToEdit?.email,
  //     });
  //     setEditing(true);
  //     setEditingId(deptName);
  //   }

  const handleEdit = (deptName) => {
    const objectiveToEdit = departmentListLocally?.find(
      (item) => item?.dept_name === deptName
    );
    if (objectiveToEdit) {
      formik.setValues({
        dept_name: objectiveToEdit.dept_name,
        email: objectiveToEdit?.email,
      });
      setEditing(true);
      setEditingId(deptName);
    }
  };

  // useEffect(() => {
  //   if (editId && departmentData) {
  //     formik.setValues({
  //       dept_name: departmentData?.dept_name,
  //       email: departmentData?.email,
  //     });
  //     setEditing(true);
  //     setEditingId(editId);
  //   }
  // }, [editId, departmentData]);

  const updateOrganization = () => {
    // const objectiveToEdit = departmentList.find(
    //   (item) => item._id === editingId
    // );
    // if (
    //   objectiveToEdit.email === formik.values.email &&
    //   objectiveToEdit.dept_name === formik.values.dept_name
    // ) {
    //   formik.resetForm();
    //   setEditing(false);
    //   setEditingId("");
    // } else {
    //   API.Auth.UpdateDepartment({
    //     data: {
    //       _id: editingId,
    //       dept_name: formik.values.dept_name,
    //       // email: formik.values.email,
    //     },
    //   })
    //     .then((response) => {
    //       if (response?.data?.statusCode === 200) {
    //         console.log(response, "response");
    //         getAlldepartments();
    //         formik.resetForm();
    //         setEditing(false);
    //         setEditingId("");
    //       } else {
    //         // Handle error or provide feedback to the user
    //         toast.error(response?.data?.message);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err, "err");
    //     });
    // }
    const indexToUpdate = departmentListLocally?.findIndex(
      (item) => item.dept_name === editingId
    );

    if (indexToUpdate !== -1) {
      const updatedList = [...departmentListLocally];
      updatedList[indexToUpdate] = {
        ...updatedList[indexToUpdate],
        dept_name: formik.values.dept_name,
      };
      setDepartmentListLocally(updatedList);
      localStorage.setItem(
        "departmentListLocally",
        JSON.stringify(updatedList)
      );
      formik.resetForm();
      setEditing(false);
      setEditingId(null);
    }
  };
  const handleNextButton = () => {
    // Store the department list in local storage
    console.log(
      "Storing department list in local storage: ",
      departmentListLocally
    );
    // Store the department list in local storage
    localStorage.setItem(
      "departmentListLocallyPublish",
      JSON.stringify(departmentListLocally)
    );

    props?.setDepartmentListLocallyPublish(departmentListLocally);
    props.handleNext();
    navigate(`../${RouteName.Auth.Registration}?id=4`, { replace: true });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    formik.resetForm();
    setEditing(false);
    setEditingId("");
  };
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Define Departments</h2>
          {/* <p className="textGrey">
            Make sure your objectives are crisp and clear
          </p> */}
          <div className="row">
            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
              <p className="textBlueLight">
                Enter the department name and assign it to respective owners
              </p>

              <form>
                <div className="mb-4">
                  <div className="form-group">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label textLightGrey mb-1"
                    >
                      Department Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter department name"
                      id="exampleInputfirstname"
                      name="dept_name"
                      value={formik.values.dept_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.errors.dept_name && formik.touched.dept_name ? (
                    <div className="text-danger">{formik.errors.dept_name}</div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label textLightGrey mb-1"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter department owner's email address"
                      id="exampleInputfirstname"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      readOnly={isEditing}
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                {!isEditing ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn set-objective-button w-100"
                      onClick={formik.handleSubmit}
                    >
                      Add Department
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn set-objective-button px-5"
                      onClick={(e) => handleCancel(e)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-white px-5"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div className="col-xl-6 ps-xl-5">
              <p className="textBlueLight">
                Here are the list of your departments and their departmental
                leaders
              </p>
              {!departmentListLocally?.length ? (
                <div className="row">
                  <div className="col-12 text-center py-5">
                    <img src={setObjective} alt="departmemnt name" />
                  </div>
                </div>
              ) : (
                <>
                  {departmentListLocally?.map((item, index) => {
                    console.log(item, "item");
                    return (
                      <div
                        className="row d-flex align-items-center w-100 mb-2"
                        key={index}
                      >
                        <div className="col-10">
                          <div className="setObjectiveListBox row p-3">
                            <div className="col-2 p-0">
                              <img
                                src={departmentIcon}
                                className=""
                                alt="department icon"
                              />
                            </div>
                            <div className="col-10 setObjectiveListBox">
                              <p
                                className="mb-1"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item?.dept_name}
                              </p>
                              <p
                                className="textBlueLight1 mb-0"
                                style={{ overflowWrap: "break-word" }}
                              >
                                {item?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex">
                            <img
                              width={36}
                              className="me-2"
                              alt="edit icon"
                              src={editIcon}
                              onClick={() => handleEdit(item?.dept_name)}
                            />
                            <button
                              type="button"
                              className="btn p-0"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => handleDelete(item?.dept_name)}
                            >
                              <img
                                src={deleteIcon}
                                width={36}
                                alt="delete icon"
                                // onClick={() => handleDelete(item?.dept_name)}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              <button
                className={`btn ${
                  !departmentListLocally?.length
                    ? "sign-up-button-color-onempty"
                    : "sign-up-button-color"
                } text-light w-100 mt-3`}
                onClick={() => handleNextButton()}
                disabled={!departmentListLocally?.length}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefineDepartment;
