import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import API from "../../Api/Api";
import { useFormik } from "formik";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { DepartmentData } from "../../Redux/Slice";
import "react-toastify/dist/ReactToastify.css";
import AddDeptPaymentModal from "./AddDeptPaymentModal";

export default function Department() {
  const [departmentList, setDepartmentList] = useState(null);
  const [newDeptList, setNewDeptList] = useState(null);
  const [newDeptList1, setNewDeptList1] = useState(null);
  const [addNewDeptFlag, setAddNewDeptFlag] = useState(false);
  const [editedNewDeptList, setEditedNewDeptList] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllDepartmentList = () => {
    API.Department.GetAllDepartmentAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          setDepartmentList(response.data.data);
          setOrganizationId(response.data.data[0].organization_id);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/");
        }
        console.log(err);
      });
  };
  useEffect(() => {
    getAllDepartmentList();
    return () => {
      console.log("Component unmounted, resetting newDeptList");
      setNewDeptList(null);
    };
  }, []);

  const validate = (values) => {
    const errors = {};
    const spaceReg = /^\S.*\S$/;
    const emailReg = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    if (!values.dept_name) {
      errors.dept_name = "Please enter department name";
    } else if (!spaceReg.test(values.dept_name)) {
      errors.dept_name = "Cannot start & end with a space";
    } else if (
      departmentList?.some((item) => item.dept_name === values.dept_name)
    ) {
      errors.dept_name = "Entered department name already exists";
    } else if (
      newDeptList?.some((item) => item.dept_name === values.dept_name)
    ) {
      errors.dept_name = "Entered department name already exists";
    }

    if (!values.dept_email) {
      errors.dept_email = "Please enter email";
    } else if (!emailReg.test(values.dept_email)) {
      errors.dept_email = "This is not a valid email address";
    } else if (
      departmentList?.some((item) => item.email === values.dept_email)
    ) {
      errors.dept_email = "Entered department email already exists";
    } else if (
      newDeptList?.some((item) => item.dept_email === values.dept_email)
    ) {
      errors.dept_email = "Entered department email already exists";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      dept_name: "",
      dept_email: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      if (addNewDeptFlag) {
        createDepartmentLocally();
      }
      formik.resetForm();
    },
    validate,
  });

  useEffect(() => {
    if (newDeptList && newDeptList.length > 0) {
      setEditedNewDeptList(newDeptList?.map((item) => ({ ...item })));
    }
  }, [newDeptList]);

  const resetFormAndState = () => {
    formik.resetForm();
    setAddNewDeptFlag(false);
    setNewDeptList(null); // Clear new department list
  };

  const createDepartmentLocally = () => {
    if (addNewDeptFlag) {
      const newObjective = {
        dept_name: formik.values.dept_name,
        dept_email: formik.values.dept_email,
      };
      const newList = newDeptList
        ? [...newDeptList, newObjective]
        : [newObjective];
      setNewDeptList(newList);
      setNewDeptList1(newList);
      setAddNewDeptFlag(false);
      formik.resetForm();
    }
  };

  const clearNewDeptList = () => {
    setNewDeptList([]);
  };

  const deleteDepartmentLocally = (index) => {
    const newList = [...newDeptList];
    newList.splice(index, 1);
    setNewDeptList(newList);
    setNewDeptList1(newList);
  };

  const NeededData = (id, name, img, fullname) => {
    console.log(id, name, img, fullname, "data");
    const newdata = {
      id: id,
      DeptName: name,
      DeptImage: img ? img : "",
      fullname: fullname ? fullname : "-",
    };
    dispatch(DepartmentData(newdata));
  };

  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12">
              <h3 className="mb-0 fw-bold">Department</h3>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6">
              <h4 className="textDarkBlack">Departments and their progress</h4>
            </div>
            <div className="col-lg-6">
              <div
                className="d-lg-flex justify-content-end"
                id="organizationModal"
              >
                {/* Button trigger modal */}
                <button
                  type="button"
                  className="btn sign-up-button-color text-white px-5"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  onClick={() => {
                    setAddNewDeptFlag(true);
                    formik.resetForm();
                  }}
                >
                  <i className="fa-solid fa-plus me-3"></i>
                  Add Department
                </button>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                  style={{ zIndex: "9999" }}
                  onHide={resetFormAndState}
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={resetFormAndState}
                        />
                      </div>
                      <div className="modal-body px-5">
                        <div
                          id="carouselExampleControls"
                          className="carousel slide col-md-11 mx-auto"
                          data-bs-ride="carousel"
                          data-interval="false"
                        >
                          {addNewDeptFlag ? (
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <div className="form-group">
                                  <h3 className="textDarkBlack mb-4">
                                    Add department
                                  </h3>
                                  <label className="textLightGrey mb-1">
                                    Department Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="Enter department name"
                                    // id="exampleInputfirstname"
                                    name="dept_name"
                                    value={formik.values.dept_name}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                                {formik.errors.dept_name &&
                                  formik.touched.dept_name ? (
                                  <div className="text-danger">
                                    {formik.errors.dept_name}
                                  </div>
                                ) : null}
                                <div className="mt-4 form-group">
                                  <label className="textLightGrey mb-2">
                                    Enter department owner's email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control mt-2"
                                    placeholder="Enter work email address"
                                    id=""
                                    name="dept_email"
                                    value={formik.values.dept_email}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                                {formik.errors.dept_email &&
                                  formik.touched.dept_email ? (
                                  <div className="text-danger">
                                    {formik.errors.dept_email}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="carousel-inner">
                                {newDeptList && newDeptList?.length ? (
                                  newDeptList?.map((item, index) => {
                                    return (
                                      <div
                                        className={`${index === newDeptList?.length - 1
                                          ? "carousel-item active"
                                          : "carousel-item"
                                          }`}
                                      >
                                        <div className="textDarkGrey">
                                          Department {index + 1}
                                        </div>
                                        <div className="button-outer d-block pb-4 ">
                                          {/* <button type="button" className="btn float-right delete-custom-btn  d-flex textBlueLight1" style={{ float: 'right' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path><path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                                            onClick={() => {
                                              console.log("ithe aalo")
                                              deleteDepartmentLocally(index)
                                            }}
                                          ></path></svg>
                                          </button> */}
                                          {newDeptList.length > 1 && (
                                            <button
                                              type="button"
                                              className="btn float-right d-flex justify-content-center align-items-center delete-custom-btn"
                                              style={{
                                                float: "right",
                                                height: "35px",
                                              }}
                                              onClick={() => {
                                                deleteDepartmentLocally(
                                                  item?.dept_name
                                                );
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-trash"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                                              </svg>
                                            </button>
                                          )}
                                        </div>
                                        <form className="position-relative">
                                          <div className="form-group">
                                            <label className="textLightGrey mb-1">
                                              Department Name
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control mt-2"
                                              placeholder="Enter department name"
                                              id="exampleInputfirstname"
                                              name="dept_name"
                                              value={
                                                editedNewDeptList[index]
                                                  ?.dept_name
                                              }
                                              onChange={(event) => {
                                                const updatedObjectives = [
                                                  ...editedNewDeptList,
                                                ];
                                                updatedObjectives[
                                                  index
                                                ].dept_name =
                                                  event.target.value;
                                                setEditedNewDeptList(
                                                  updatedObjectives
                                                );
                                              }}
                                            />
                                          </div>
                                          {/* {formik.errors.dept_name &&
                                            formik.touched.dept_name ? (
                                            <div className="text-danger">
                                              {formik.errors.dept_name}
                                            </div>
                                          ) : null} */}

                                          <div className="mt-4 form-group">
                                            <label className="textLightGrey mb-2">
                                              Enter department owner's email
                                            </label>
                                            <input
                                              type="email"
                                              className="form-control mt-2"
                                              placeholder="Enter work email address"
                                              id=""
                                              name="email"
                                              value={
                                                editedNewDeptList[index]
                                                  ?.dept_email
                                              }
                                              onChange={(event) => {
                                                const updatedObjectives = [
                                                  ...editedNewDeptList,
                                                ];
                                                updatedObjectives[
                                                  index
                                                ].dept_email =
                                                  event.target.value;
                                                setEditedNewDeptList(
                                                  updatedObjectives
                                                );
                                              }}
                                            />
                                          </div>
                                          {/* {formik.errors.dept_email && formik.touched.dept_email ? (
                                            <div className="text-danger">
                                              {formik.errors.dept_email}
                                            </div>
                                          ) : null} */}
                                        </form>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <h4>Please add department!</h4>
                                )}
                              </div>
                            </>
                          )}
                          <div className="form-bottom d-flex justify-content-between mt-3">
                            <button
                              type="button"
                              className="btn sign-up-button-color btn-primary text-white px-5 mt-3"
                              onClick={() => {
                                setAddNewDeptFlag(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"></path>
                              </svg>
                              Add New
                            </button>
                            {addNewDeptFlag ? (
                              <button
                                type="button"
                                className="btn sign-up-button-color  btn-primary text-white px-5 mt-3"
                                onClick={() => {
                                  formik.handleSubmit();
                                }}
                              // data-bs-target="#addDeptPaymentModal"
                              // data-bs-toggle="modal"
                              // data-bs-dismiss="modal"
                              // disabled={addNewDeptFlag && (!formik.values.dept_name || !formik.values.dept_email)}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn sign-up-button-color  btn-primary text-white px-5 mt-3"
                                onClick={formik.handleSubmit}
                                data-bs-target="#addDeptPaymentModal"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                              // disabled={addNewDeptFlag && (!formik.values.dept_name || !formik.values.dept_email)}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                          {!addNewDeptFlag && newDeptList && newDeptList.length > 1 && (
                            <>
                              <button
                                className="carousel-control-prev objective-prev-btn "
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide="prev"
                              >
                                <span
                                  className="carousel-control-prev-icon "
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button
                                className="carousel-control-next objective-next-btn"
                                type="button"
                                data-bs-target="#carouselExampleControls"
                                data-bs-slide="next"
                              >
                                <span
                                  className="carousel-control-next-icon"
                                  aria-hidden="true"
                                ></span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="modal-footer border-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {departmentList &&
              departmentList.map((item, index) => {
                return (
                  <div className="col-xxl-4 col-lg-6 col-12 mb-3" key={index}>
                    <NavLink
                      className="text-decoration-none"
                      to={`/department-details/${item?._id}`}
                      onClick={() =>
                        NeededData(
                          item?._id,
                          item?.dept_name,
                          item?.profileImageUrl,
                          item?.fullName
                        )
                      }
                    >
                      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-2">
                        <div className="d-sm-flex align-items-center">
                          <img
                            src={
                              item?.profileImageUrl
                                ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                : departmentImg
                            }
                            className="me-3 Organization-img"
                            alt="profile"
                          />
                          <div>
                            <h3 className="textDarkBlack mb-1 fw-bold msm">
                              {item?.dept_name}
                            </h3>
                            <p className="lightGrey mb-0">{item?.fullName}</p>
                          </div>
                        </div>
                        <div style={{ width: "100px", height: "100px" }}>
                          <CircularProgressbar
                            value={item?.dept_growth}
                            text={`${item?.dept_growth > 0
                              ? item?.dept_growth?.toFixed(2)
                              : item?.dept_growth
                              }%`}
                            strokeWidth={10}
                            styles={buildStyles({
                              rotation: 0.0,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              className: "bold-text",
                              pathTransitionDuration: 0.5,
                              pathColor: `#6993ff, ${item?.dept_growth / 100})`,
                              textColor: "#6993ff",
                              trailColor: "#d6d6d6",
                              backgroundColor: "#3e98c7",
                            })}
                          />
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
          </div>

          <AddDeptPaymentModal
            newDeptList={newDeptList}
            setDepartmentList={setDepartmentList}
            organizationId={organizationId}
            clearNewDeptList={clearNewDeptList}
            newDeptList1={newDeptList1}
          />
        </div>
      </div>
    </AppLayout>
  );
}
