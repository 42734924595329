import React, { useRef, useState, useEffect } from "react";
/* eslint-disable */
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import otpSentIcon from "../../assets/images/Icons/Pics/Icon awesome-check-circle.png";
import RouteName from "../../Routes/Routename";
import LoginBanner from "../comman/LoginBanner";

const VerifyOtp = () => {
  const email = localStorage.getItem("EmailID");
  console.log(email, "email");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [newOtpData, setnewOtpData] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [counter, setCounter] = useState(60);
  const [popUpResponse, setPopupResponse] = useState("");
  const navigate = useNavigate();
  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleChange = (event, index) => {
    const { value } = event.target;
    console.log("value", value);

    // Efficiently handle pasted content
    if (value.length > 1) {
      const pastedNumbers = value.slice(0, 4);
      setOtp((prevOtp) => [...pastedNumbers]);
      return;
    }

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value.slice(0, 1);
      return newOtp;
    });
    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };
  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    console.log("newotp", newotp);
    setFormErrors(" ");

    if (!newotp) {
      setFormErrors("Please Enter otp");
    } else {
      try {
        API.Auth.VerifyOTP({
          data: {
            email: email,
            otp: newotp,
          },
        }).then((response) => {
          if (response?.data?.statusCode === 200) {
            console.log(response.data);
            // Show popup
            setPopupOpen(true);
            setPopupResponse("Email verified Successfully");
            // Close the popup and navigate after a delay
            setTimeout(() => {
              setPopupOpen(false);

              // Optionally, you can perform additional actions after the delay
              localStorage.setItem("managementToken", response.data.token);
              localStorage.setItem(
                "subscriptionDetails",
                JSON.stringify(response.data.subscription)
              );

              if (response.data.data.flag === 2) {
                navigate(`../${RouteName.Auth.Registration}?id=2`, {
                  replace: true,
                });
              } else if (response.data.data.flag === 3) {
                navigate(`../${RouteName.Auth.Registration}?id=3`, {
                  replace: true,
                });
              } else if (response.data.data.flag === 4) {
                navigate(`../${RouteName.Auth.Registration}?id=4`, {
                  replace: true,
                });
              } else if (
                response.data.data.flag === 5 &&
                response.data.data.org_Info_filled === false
              ) {
                navigate(`../${RouteName.Auth.Registration}?id=4`, {
                  replace: true,
                });
              } else if (
                response.data.data.flag === 5 &&
                response.data.data.org_Info_filled === true
              ) {
                navigate("/dashboard");
              }
            }, 1000);
          } else if (response?.data?.statusCode === 400) {
            setFormErrors(response.data.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // ===== automatically close the popup after a certain period of time ===========
  useEffect(() => {
    if (isPopupOpen) {
      const timeoutId = setTimeout(() => {
        closePopup();
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [isPopupOpen]);

  // ===== Resend Otp ===========

  useEffect(() => {
    if (counter) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
    // Time == 0 ? setotperror(true) : null;
  }, [counter]);

  const handleResendOtp = (e) => {
    setOtp(["", "", "", ""]);
    setFormErrors("");
    e.preventDefault();
    // openPopup()
    setCounter(60);
    try {
      API.Auth.ResendLoginOTP({
        data: {
          email: email,
          // resendOtp: "",
          flag: 5,
        },
      }).then((response) => {
        console.log(response.data);
        if (response?.data?.statusCode === 200) {
          setPopupResponse("OTP sent successfully");
          setTimeout(() => {
            setPopupOpen(true);
          }, 1000);
          setnewOtpData(response?.data?.data);
        } else if (response?.data?.statusCode === 400) {
          setFormErrors("Please Enter valid otp");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="container-fluid main-container vh-100 p-0">
          <div className="row h-100">
            <LoginBanner />
            <div className="col-lg-6 col-12 vh-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                {/* OTP Fields */}

                <div>
                  {!isPopupOpen ? (
                    <div className="registration-form-container">
                      <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12">
                          <div className="text-center">
                            <h1 className="bannerHeading fs-3 mb-5">
                              EASY<span className=" fs-3">OKR</span>
                            </h1>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-1">
                            <NavLink to="/">
                              <div className="backArrow me-3">
                                <i className="fa fa-solid fa-chevron-left textDarkBlue"></i>
                              </div>
                            </NavLink>
                          </div>
                          <div className="col-11">
                            <h3 className="mb-2 textDark">Verify</h3>
                            <div className="col-12 mb-4">
                              <small className="textDark">
                                Enter 4 digit OTP sent on the email address '
                                {email}'
                              </small>
                              <p className="text-danger">{formErrors}</p>
                            </div>

                            <div className="col-12">
                              <div className="row mb-3 text-center">
                                {otp.map((value, index) => (
                                  <div className="col-3">
                                    <input
                                      key={index}
                                      onKeyPress={(event) => {
                                        // Allow only numeric values using onKeyPress
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      type="number"
                                      placeholder="-"
                                      id={`otp-${index}`}
                                      name={`otp-${index}`}
                                      value={value}
                                      onChange={(event) =>
                                        handleChange(event, index)
                                      }
                                      maxLength="1"
                                      ref={inputRefs[index]}
                                      className={`${
                                        formErrors
                                          ? "border-danger"
                                          : "border-none"
                                      } form-control custom-input-text-primary otp-input mb-0 text-center`}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                            <button
                              className={`btn ${
                                otp.join("").length === 4
                                  ? "sign-up-button-color"
                                  : "sign-up-button-color-onempty"
                              } text-light w-100`}
                              onClick={(e) => handleVerifyOtp(e)}
                              // disabled={otp.join("").length !== 4}
                            >
                              Verify
                            </button>

                            {counter === 0 ? (
                              <div className="row">
                                <div className="col-12">
                                  <p className="mt-2 mb-0">
                                    Didn&rsquo;t Receive OTP?
                                  </p>
                                  <button
                                    className="ms-1 btn p-0 text-decoration-underline"
                                    onClick={(e) => handleResendOtp(e)}
                                  >
                                    {" "}
                                    Resend OTP
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="row text-center">
                                <p className=" mt-2 ">
                                  Resend OTP in{" "}
                                  <b>
                                    {counter == 60
                                      ? `00:60`
                                      : counter >= 10
                                      ? `00:${counter}`
                                      : `00:0${counter}`}
                                  </b>{" "}
                                  Sec
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="registration-form-container position-relative">
                        <div className="text-center mb-5">
                          <img src={otpSentIcon} />
                        </div>
                        <div className="text-center px-4 mt-2">
                          <h2 className="text-center fw-bold letter-spacing textDark">
                            {popUpResponse}
                          </h2>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
