import BaseApi from "./BaseApi";

const API = {
   Auth: {
      RegisterAPI: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/register`,
            data: props.data,
            tokenRequired: false,
         });
      },
      CreateOrgObjectiveAPI: (props) => {
         return BaseApi({
            method: "POST",
            url: `/objective/create_organization_objective`,
            data: props.data,
            tokenRequired: true,
         });
      },

      GetAllOrgObjectiveAPI: (props) => {
         return BaseApi({
            method: "GET",
            url: `/objective/get_organization_objective`,
            // url: `/objective/get_organization_objective?_id=${props?.userID}`,
            // data: props.data,
            tokenRequired: true,
         });
      },

      DeactivateObjective: (props) => {
         return BaseApi({
            method: "POST",
            url: `/objective/deactivate_organization_objective`,
            data: props.data,
            tokenRequired: true,
         });
      },
      UpdateObjective: (props) => {
         return BaseApi({
            method: "POST",
            url: `/objective/update_organization_objective`,
            data: props.data,
            tokenRequired: true,
         });
      },
      // department API's
      CreateDepartment: (props) => {
         return BaseApi({
            method: "POST",
            url: `/department/create_department_by_org`,
            data: props.data,
            tokenRequired: true,
         });
      },
      GetAllOrgDepartmentsAPI: (props) => {
         return BaseApi({
            method: "GET",
            url: `/department/get_department_by_org`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      GetOrgDepartmentByIdAPI: (props) => {
         return BaseApi({
            method: "GET",
            url: `/organization/get_objectives_list?departmentId=${props}`,
            tokenRequired: true,
         });
      },
      GetOrgDepartmentByIdAPIForDashboard: (props) => {
         return BaseApi({
            method: "GET",
            url: `/organization/get_objectives_list?departmentId=${props?.data?.departmentId}`,
            tokenRequired: true,
         });
      },
      DeactivateDepartment: (props) => {
         return BaseApi({
            method: "POST",
            url: `/department/deactivate_department_by_org`,
            data: props.data,
            tokenRequired: true,
         });
      },

      UpdateDepartment: (props) => {
         return BaseApi({
            method: "POST",
            url: `/department/update_department_by_org`,
            data: props.data,
            tokenRequired: true,
         });
      },
      // Departmemt api end
      ShareInviteAPI: (props) => {
         return BaseApi({
            method: "POST",
            url: `/department/invite`,
            data: props.data,
            tokenRequired: true,
         });
      },
      ShareInviteSubmitAPI: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/update_organization`,
            data: props.data,
            tokenRequired: true,
         });
      },

      LoginManagementAPI: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/login_organization`,
            data: props.data,
            tokenRequired: false,
         });
      },
      VerifyOTP: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/verifyOtp`,
            data: props.data,
            tokenRequired: false,
         });
      },

      ResendLoginOTP: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/resendOtp`,
            data: props.data,
            tokenRequired: false,
         });
      },
      ResendOtp: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/register`,
            data: props.data,
            tokenRequired: false,
         });
      },
      GetSingleDeptInfo: (props) => {
         return BaseApi({
            method: "GET",
            url: `/department/get_department_by_org?_id=${props?.editId}`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      createPaymentOrder: (props) => {
         return BaseApi({
            method: "POST",
            url: `/payment/create_payment_order`,
            data: props.data,
            tokenRequired: true,
         });
      },
      createMultipleDepartment: (props) => {
         return BaseApi({
            method: "POST",
            url: `/department/create_multiple_departments`,
            data: props.data,
            tokenRequired: true,
         });
      },
      createSubscription: (props) => {
         return BaseApi({
            method: "POST",
            url: `/payment/update_subscription_details`,
            data: props.data,
            tokenRequired: true,
         });
      },
      ValidateCoupon: (props) => {
         return BaseApi({
            method: "POST",
            url: `/payment/validate-coupon`,
            data: props.data,
            tokenRequired: true,
         });
      },
      getSubscriptionData: (props) => {
         return BaseApi({
            method: "GET",
            url: `/payment/subscription/${props}`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      getInvoicePDF: (data) => {
         return BaseApi({
            method: "POST",
            url: `/payment/generate_payment_invoice`,
            data: data,
            tokenRequired: true,
         });
      },
      getSubscriptionDetails: (props) => {
         return BaseApi({
            method: "GET",
            url: `/payment/get_subscription_details?departmentCount=${props}`,
            tokenRequired: true,
         });
      },
      getCalculationsData: (props) => {
         return BaseApi({
            method: "GET",
            url: `/payment/get_new_department_subscription_details?departmentCount=${props}`,
            tokenRequired: true,
         });
      },
   },

   DashBoard: {
      getOrgWithObjAPI: () => {
         return BaseApi({
            method: "GET",
            url: `/organization/getOrganization_with_objectives`,

            tokenRequired: true,
         });
      },

      getDashboardData: (props) => {
         return BaseApi({
            method: "GET",
            url: `/organization/get_dashboard_details?start_date=${props.sDate}&end_date=${props.EDate}`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      getExprirationDateData: () => {
         return BaseApi({
            method: "GET",
            url: `/organization/get_subscription_details`,
            // data: props.data,
            tokenRequired: true,
         });
      },

      getDepartmentYears: () => {
         return BaseApi({
            method: "GET",
            url: `/organization/get_org_year_quarter`,
            // data: props.data,
            tokenRequired: true,
         });
      },
   },

   Department: {
      GetAllDepartmentAPI: (props) => {
         return BaseApi({
            method: "GET",
            url: `/organization/department_progress_list`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      GetAllKeyResultAPI: (props) => {
         console.log(props);
         return BaseApi({
            method: "GET",
            url: `/key_result/key_result_score_list_by_org?department_objective_id=${props}`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      UpdateWeightageScore: (props) => {
         return BaseApi({
            method: "POST",
            url: `key_result/addWeightageToTask`,
            data: props.data,
            tokenRequired: true,
         });
      },
   },
   Profile: {
      GetMyProfileAPI: (props) => {
         return BaseApi({
            method: "GET",
            url: `/organization/get_organization`,
            // data: props.data,
            tokenRequired: true,
         });
      },

      uploadProfileImg: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/fileUpload`,
            data: props.data,
            tokenRequired: false,
         });
      },

      UpdateProfileDetails: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/update_organization`,
            data: props?.data,
            tokenRequired: true,
         });
      },
   },
   userManagement: {
      GetDepartmentListByStatusAPI: (props) => {
         return BaseApi({
            method: "POST",
            url: `/organization/department_list_by_status`,
            data: props.data,
            tokenRequired: true,
         });
      },
      GetSingleUserInfo: (props) => {
         return BaseApi({
            method: "GET",
            url: `/department/get_department_by_org?_id=${props?.DeptId}`,
            // data: props.data,
            tokenRequired: true,
         });
      },
      updateUserInfo: (props) => {
         return BaseApi({
            method: "POST",
            url: `/department/update_department_by_org`,
            data: props.data,
            tokenRequired: true,
         });
      },
   },
   scoreManagement: {
      saveMeetingDetails: (props) => {
         console.log("propssss", props);
         return BaseApi({
            method: "POST",
            url: `/organization/save_meeting_details`,
            data: props,
            tokenRequired: true,
         });
      },
      loadMeetingDetails: () => {
         console.log("api call successful");
         return BaseApi({
            method: "GET",
            url: `/organization/get_organization`,
            tokenRequired: true,
         });
      },
   },
   PlanRenew: {
      departmentProgressList: () => {
         return BaseApi({
            method: "GET",
            url: `/department/get_renewal_department_list`,
            tokenRequired: true,
         });
      },
      departmentStatusChange: (props) => {
         return BaseApi({
            method: "POST",
            url: `department/change_department_status`,
            data: props.data,
            tokenRequired: true,
         });
      },

      createDepartmentRenew: (props) => {
         return BaseApi({
            method: "POST",
            url: `department/create_department_by_org`,
            data: props.data,
            tokenRequired: true,
         });
      },
      updateDepartmentRenewal: (props) => {
         return BaseApi({
            method: "POST",
            url: `department/update_department_by_org`,
            data: props.data,
            tokenRequired: true,
         });
      },
      getAllDeletedUsers: () => {
         return BaseApi({
            method: "GET",
            url: `department/get_all_deleted_users`,
            tokenRequired: true,
         });
      },
      downloadExcelFile: (props) => {
         return BaseApi({
            method: "POST",
            url: `organization/download_okr_sheet`,
            data: props.data,
            tokenRequired: true,
         });
      },
   },
};

export default API;
