import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import baseApi from "../../Api/config";
import AppLayout from "../Layout/AppLayout";
import companyLogo from "../../assets/images/Icons/Pics/Icon featherimage.png";
import profileImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function MyProfile() {
  const [profileDetails, setProfileDetails] = useState();
  const [editableField, setEditableField] = useState(false);
  const [editVisionField, setEditVisonField] = useState(false);

  const handleEdit = () => {
    setEditableField(!editableField);
    setEditVisonField(false);
  };
  const handleVisionEdit = () => {
    setEditVisonField(!editVisionField);
    setEditableField(false);
  };
  // upload logo and profile
  const [imagePath1, setImagePath1] = useState(null);
  const [imagePath2, setImagePath2] = useState(null);
  const navigate = useNavigate();
  // GEt profile details API
  const getProfiledetails = () => {
    API.Profile.GetMyProfileAPI()
      .then((response) => {
        console.log(response);
        setProfileDetails(response.data.data);
        setImagePath1(response.data.data?.profileImageUrl);
        setImagePath2(response.data.data?.companyLogoUrl);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getProfiledetails();
  }, []);

  const formik = useFormik({
    initialValues: {
      mission: profileDetails?.mission || "", // Ensure a default value if profileDetails?.mission is undefined
      vision: profileDetails?.vision || "",
      profileImageUrl: profileDetails?.profileImageUrl || "",
      companyLogoUrl: profileDetails?.companyLogoUrl || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mission: Yup.string().required("Mission cannot be empty"),
      vision: Yup.string().required("Vision cannot be empty"),
    }),
    onSubmit: (values) => {
      console.log(values, "hello values");
      updateProfileAPI();
    },
    // validate,
  });

  const handleUpload = async (event) => {
    event.persist();
    console.log(formik.values.profileImageUrl);
    if (event.target.files[0]) {
      try {
        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        const response = await axios.post(
          `${baseApi.baseurl}/organization/fileUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.statusCode === 200) {
          setImagePath1(response?.data?.filename);
          console.log(response.data.filename); // Handle the response from the backend as needed
          updateProfileAPI(response?.data?.filename, "ProfileImg");
          // updateProfileAPI(response?.data?.filename);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  const handleLogoUpload = async (event) => {
    event.persist();
    console.log(formik.values.profileImageUrl);
    if (event.target.files[0]) {
      try {
        const formData = new FormData();
        formData.append("image", event.target.files[0]);

        const response = await axios.post(
          `${baseApi.baseurl}/organization/fileUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.statusCode === 200) {
          setImagePath2(response?.data?.filename);
          // updateProfileAPI(response?.data?.filename);
          updateProfileAPI(response?.data?.filename, "CompLogo");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  // update Profile API

  const updateProfileAPI = (data, type) => {
    console.log("data", data);
    if (!formik.values.mission || !formik.values.vision) {
      formik.setFieldTouched("mission", true);
      formik.setFieldTouched("vision", true);
      return;
    }
    API.Profile.UpdateProfileDetails({
      data: {
        mission: formik.values.mission,
        vision: formik.values.vision,
        profileImageUrl: type === "ProfileImg" ? data : imagePath1,
        companyLogoUrl: type === "CompLogo" ? data : imagePath2,
      },
    })
      .then((response) => {
        console.log(response);
        getProfiledetails();
        setEditVisonField(false);
        setEditableField(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="progressCard">
              <div className=" welcomeHeading top-border-radius pb-5">
                <div className="d-flex align-items-center ps-3 pb-5">
                  <NavLink to="/dashboard">
                    <div className="backArrow me-2">
                      <i className="fa fa-solid fa-chevron-left textDark"></i>
                    </div>
                  </NavLink>
                  <h4 className="mb-0 textDark">My Account</h4>
                </div>
              </div>
              <div className="row position-relative">
                <div className="col-12 profileImg float-start">
                  <p className="addUserPic mx-auto mt-1 mb-1">
                    <button className="addPicIcon border-0">
                      <i className="fas fa-pen"></i>
                    </button>
                    {/* <img
                      src={
                        profileDetails?.profileImageUrl
                          ? profileDetails?.profileImageUrl
                          : profileImg
                      }
                      className="img-fluid profileSize"
                    /> */}

                    <img
                      src={
                        imagePath1
                          ? `${process.env.REACT_APP_BASE_URL}/images/${imagePath1}`
                          : profileImg
                      }
                      className="img-fluid profileSize"
                    />

                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      name="media"
                      multiple="multiple"
                      accept="image/*"
                      onChange={(e) => handleUpload(e)}
                    />

                    <label
                      className="custom-file-label mb-0"
                      htmlFor="customFile"
                    ></label>
                  </p>

                  <h3 className="text-center textBlue font14 mb-1 pt-3 fw-600">
                    {profileDetails?.fullName ? profileDetails.fullName : "-"}
                  </h3>
                  <p className="text-center font14 mb-1 textLightGrey">
                    Management
                  </p>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-12">
                  <h3 className="textBlue fw-600">Company Details</h3>
                  <div className="row mt-4">
                    <div className="col-xl-6 mb-4">
                      <div className="row">
                        <div className="col-md-6 mb-3" id="profileCompanyLogo">
                          <h4 className="textLightGrey fw-600">Company Logo</h4>
                          <p className="companylogo addUserPic mt-1 mb-1">
                            {/* <span className="addPicIcon">
                      <i className="fas fa-pen"></i>
                    </span> */}
                            <div>
                              <img
                                src={
                                  imagePath2
                                    ? `${process.env.REACT_APP_BASE_URL}/images/${imagePath2}`
                                    : companyLogo
                                }
                                style={{
                                  height: "87px",
                                  width: "147px",
                                  objectFit: "cover",
                                }}
                                className="rounded"
                              />
                            </div>
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile1"
                              name="logo"
                              multiple="multiple"
                              accept="image/*"
                              onChange={(e) => handleLogoUpload(e)}
                            />

                            {/* <div className="uplodedImg d-flex align-items-center rounded-circle">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                name="media"
                                multiple="multiple"
                                accept="image/*"
                              />
                            </div> */}
                            <label
                              className="custom-file-label mb-0"
                              htmlFor="customFile1"
                            ></label>
                          </p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <h4 className="textLightGrey fw-600">Company Name</h4>
                          <p className="textDarkGrey">
                            {profileDetails &&
                              profileDetails?.organization_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <h4 className="textLightGrey fw-600">CIN</h4>
                          <p className="textDarkGrey">
                            {profileDetails && profileDetails?.cin}
                          </p>
                        </div>
                        <div className="col-md-6 mb-3">
                          <h4 className="textLightGrey">Work Email Address</h4>
                          <p className="textDarkGrey">
                            {" "}
                            {profileDetails && profileDetails?.work_email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-4">
                <div className="col-12">
                  <h3 className="textBlue fw-600">Company Mission & Vision</h3>
                  <div className="row mt-4">
                    <div className="row">
                      <div className="col-xl-6 mb-4">
                        <h4 className="textLightGrey fw-600">Mission</h4>
                        <div className="row d-flex align-items-center">
                          <div className="col-11">
                            <textarea
                              className={`missionText textDarkBlack mb-0 w-100 ${
                                editableField ? "" : "editable"
                              }`}
                              name="mission"
                              onChange={formik.handleChange}
                              value={formik.values.mission}
                              onBlur={formik.handleBlur}
                              readOnly={!editableField}
                              maxLength={500}
                            />
                            {formik.touched.mission && formik.errors.mission ? (
                              <div className="text-danger">
                                {formik.errors.mission}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-1">
                            {!editableField ? (
                              <i
                                className="fas fa-pen textDarkBlue"
                                onClick={() => handleEdit()}
                              >
                                _
                              </i>
                            ) : (
                              <i
                                className="fas fa-save textDarkBlue fs-3"
                                onClick={() => updateProfileAPI()}
                              ></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <h4 className="textLightGrey fw-600">Vision</h4>
                        <div className="row d-flex align-items-center">
                          <div className="col-11">
                            <textarea
                              className={`missionText textDarkBlack mb-0 w-100 ${
                                editVisionField ? "" : "editable"
                              }`}
                              onChange={formik.handleChange}
                              name="vision"
                              value={formik.values.vision}
                              onBlur={formik.handleBlur}
                              readOnly={!editVisionField}
                              maxLength={500}
                            />
                            {formik.touched.vision && formik.errors.vision ? (
                              <div className="text-danger">
                                {formik.errors.vision}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-1">
                            {!editVisionField ? (
                              <i
                                className="fas fa-pen textDarkBlue"
                                onClick={() => handleVisionEdit()}
                              >
                                _
                              </i>
                            ) : (
                              <i
                                className="fas fa-save textDarkBlue fs-3"
                                onClick={() => updateProfileAPI()}
                              ></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-12">
                  <button
                    // to="/settings-details-edit"
                    type="button"
                    className="btn btnOrganization py-1 w-auto px-5 float-end"
                    onClick={formik.handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
